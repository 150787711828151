<script setup lang="ts">
const { user } = useUser();
const activeTeam = useActiveTeam();
import {
  IconTruckOutline,
  IconUserGroupOutline,
  IconUsersOutline,
  IconUserOutline,
  IconBuildingOffice2Outline,
  IconRssOutline,
  IconCodeBracket,
  IconCurrencyDollarOutline,
  IconGlobeAltOutline,
  IconSquare2StackOutline,
  IconListBullet,
  IconChevronDownOutline,
  IconChevronUpOutline,
  IconKeyOutline,
} from "#components";

watch(
  user,
  (user) => {
    if (!user) {
      navigateTo("/auth/login");
    }
  },
  { immediate: true }
);

// Helper function to generate team or admin links based on user permissions
const getLink = (adminPath: string, teamPath: string) => {
  return user.value?.isOnAdminTeam
    ? adminPath
    : `${teamPath}/${activeTeam.value?.id}${adminPath}`;
};

const closed = useLocalStorage("admin-menu-closed", false);

// Track which submenu is currently expanded (empty string means none)
const expandedSubmenu = ref("");

// Toggle submenu expansion
const toggleSubmenu = (menuId: string) => {
  if (expandedSubmenu.value === menuId) {
    // If clicking on already expanded menu, close it
    expandedSubmenu.value = "";
  } else {
    // Otherwise, expand the clicked menu and close others
    expandedSubmenu.value = menuId;
  }
};

// Check if a submenu is expanded
const isSubmenuExpanded = (menuId: string) => {
  return expandedSubmenu.value === menuId;
};

// Define menu items as a reactive array
const menuItems = computed(() => [
  {
    id: "jobs",
    label: "Jobs",
    icon: IconTruckOutline,
    link: getLink("/admin/jobs", "/admin/teams"),
    visible: true, // Always visible
    active: (route: string) => route.startsWith("/admin/jobs"),
  },
  {
    id: "leads",
    label: "Leads",
    icon: IconUserGroupOutline,
    link: getLink("/admin/leads", "/admin/teams"),
    visible:
      user.value?.isOnAdminTeam ||
      activeTeam.value?.permissionsOnTeam.includes("leads:read"),
    active: (route: string) => route.startsWith("/admin/leads"),
  },
  {
    id: "companies",
    label: "Companies",
    icon: IconBuildingOffice2Outline,
    link: getLink("/admin/companies", "/admin/teams"),
    visible: user.value?.isOnAdminTeam,
    active: (route: string) => route.startsWith("/admin/companies"),
  },
  {
    id: "feeds",
    label: "Feeds",
    icon: IconRssOutline,
    link: "/admin/feeds",
    visible: user.value?.isOnAdminTeam,
    active: (route: string) => route.startsWith("/admin/feeds"),
  },
  {
    id: "forms",
    label: "Forms",
    icon: IconListBullet,
    link: "/admin/forms",
    visible: user.value?.isOnAdminTeam,
    active: (route: string) => route.startsWith("/admin/forms"),
  },
  {
    id: "snippets",
    label: "Snippets",
    icon: IconCodeBracket,
    link: "/admin/snippets",
    visible: user.value?.isOnAdminTeam,
    active: (route: string) => route.startsWith("/admin/snippets"),
  },
  {
    id: "apiKeys",
    label: "API Keys",
    icon: IconKeyOutline,
    link: "/admin/api-keys",
    visible: user.value?.isOnAdminTeam,
    active: (route: string) => route.startsWith("/admin/api-keys"),
  },
  {
    id: "adAccounts",
    label: "Ad Accounts",
    icon: IconCurrencyDollarOutline,
    isSubmenu: true,
    visible: user.value?.isOnAdminTeam,
    active: (route: string) => route.startsWith("/admin/ads"),
    submenuItems: [
      {
        id: "googleAds",
        label: "Google Ads",
        icon: IconGlobeAltOutline,
        link: "/admin/ads/google",
        active: (route: string) => route.startsWith("/admin/ads/google"),
      },
      {
        id: "facebookAds",
        label: "Facebook Ads",
        icon: IconSquare2StackOutline,
        link: "/admin/ads/facebook",
        active: (route: string) => route.startsWith("/admin/ads/facebook"),
      },
    ],
  },
  {
    id: "users",
    label: "Users",
    icon: IconUsersOutline,
    isSubmenu: true,
    visible:
      user.value?.isOnAdminTeam ||
      activeTeam.value?.permissionsOnTeam.includes("users:read-team"),
    active: (route: string) =>
      route.startsWith("/admin/users") ||
      (route.startsWith("/admin/teams") && route.endsWith("/users")),
    submenuItems: [
      {
        id: "allUsers",
        label: "All Users",
        icon: IconUsersOutline,
        link: "/admin/users",
        visible: user.value?.isOnAdminTeam,
        active: (route: string) => route.startsWith("/admin/users"),
      },
      {
        id: "teamUsers",
        label: user.value?.isOnAdminTeam ? "Admin Users" : "Users",
        icon: IconUserOutline,
        link: `/admin/teams/${activeTeam.value?.id}/users`,
        visible:
          activeTeam.value?.permissionsOnTeam.includes("users:read-team"),
        active: (route: string) =>
          route.startsWith("/admin/teams") && route.endsWith("/users"),
      },
    ],
  },
]);

// For menu toggle icons
const IconArrowLeftCircleOutline = resolveComponent(
  "IconArrowLeftCircleOutline"
);
const IconArrowRightCircleOutline = resolveComponent(
  "IconArrowRightCircleOutline"
);
</script>
<template>
  <div
    v-if="user && activeTeam"
    class="w-[250px] h-full menu-wrapper bg-gray-100 border-r-2 border-gray-300 overflow-hidden text-sm"
    :class="{
      closed: closed,
    }"
  >
    <ul class="flex flex-col gap-1 p-2 menu">
      <!-- Render menu items from the array -->
      <li
        v-for="item in menuItems"
        :key="item.id"
        v-show="item.visible"
        :class="{ 'relative menu-with-submenu': item.isSubmenu }"
      >
        <!-- Regular menu item -->
        <template v-if="!item.isSubmenu">
          <NuxtLink
            :to="item.link"
            :class="{
              active: item.active($route.path),
            }"
          >
            <component :is="item.icon" class="inline-block w-6 h-6" />
            <span class="menu-item-label"> {{ item.label }} </span>
          </NuxtLink>
        </template>

        <!-- Submenu item -->
        <template v-else>
          <a
            href="#"
            @click.prevent="toggleSubmenu(item.id)"
            class="flex justify-between items-center"
            :class="{
              active: item.active($route.path),
            }"
          >
            <div class="flex gap-2 items-center">
              <component :is="item.icon" class="inline-block w-6 h-6" />
              <span class="menu-item-label">{{ item.label }}</span>
            </div>
            <component
              :is="
                isSubmenuExpanded(item.id)
                  ? IconChevronUpOutline
                  : IconChevronDownOutline
              "
              class="inline-block ml-1 w-4 h-4"
            />
          </a>
          <!-- Submenu items -->
          <ul v-show="isSubmenuExpanded(item.id)" class="pl-6 mt-1 submenu">
            <li v-for="subItem in item.submenuItems" :key="subItem.id">
              <NuxtLink
                :to="subItem.link"
                :class="{
                  active: subItem.active($route.path),
                }"
              >
                <component
                  :is="subItem.icon"
                  class="inline-block mr-1 w-5 h-5"
                />
                <span class="menu-item-label">{{ subItem.label }}</span>
              </NuxtLink>
            </li>
          </ul>
        </template>
      </li>

      <!-- Toggle menu button -->
      <li class="!bg-transparent">
        <a @click.prevent="closed = !closed">
          <component
            :is="
              closed ? IconArrowRightCircleOutline : IconArrowLeftCircleOutline
            "
            class="inline-block w-6 h-6"
          />
        </a>
      </li>
    </ul>
  </div>
</template>

<style scoped>
.active {
  background-color: oklch(var(--bc) / 0.1);
}
.closed {
  width: 70px !important;
  overflow: hidden;
}
.menu-item-label {
  white-space: nowrap;
}

@keyframes slideClosed {
  0% {
    width: 200px;
  }
  100% {
    width: 0;
  }
}
.closed .menu-item-label {
  animation: slideClosed 0.2s ease-in-out;
  width: 0;
  display: inline-block;
  overflow: hidden;
}
.menu-wrapper {
  transition: width 0.2s ease-in-out;
}

/* Styles for submenu */
.menu-with-submenu > a {
  display: flex;
  align-items: center;
}

.submenu li {
  margin-bottom: 0.5rem;
}

.closed .submenu {
  display: none;
}
</style>
